<template>
  <div class="">
    <div class="row">
      <div class="colr bg-clr col-md-7 pad-setting">
        <h1 class="text-center pb-3">Vite for business</h1>
        <p>
          At Vite, our goal is to Move Mauritius, Everyday! that includes your
          employees. The Vite Team has built an innovative software solution
          that simplifies how people find and book local taxis and access
          delivery services. Using Vite is as easy as pushing a button from your
          phone, sending a message, or making a call because that’s all you have
          to do to request a trip.
        </p>
        <h5 class="bold">The benefits of using Vite for your business are:</h5>
        <ul>
          <li>A reliable means of transportation for your employees</li>
          <li>25% cheaper than traditional taxis</li>
          <li>It’s a great employee incentive</li>
        </ul>
      </div>
      <div class="col-md-5">
        <div class="col-md-9 position-form border-set p-0">
          <div class="py-4 bg-secondary colr">
            <h3 class="text-center">Vite for business</h3>
          </div>
          <div class="py-5 px-5">
            <b-form @submit="businessfor">
              <b-form-group>
                <b-form-input
                  v-model="companyName"
                  placeholder="Company name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-select
                  selected
                  v-model="companySize"
                  :options="size"
                  required
                ></b-form-select>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="companyPhone"
                  placeholder="Phone"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="companyMail"
                  type="email"
                  placeholder="Email"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="Comments"
                  rows="3"
                ></b-form-textarea>
              </b-form-group>
              <b-button block class="btn btn-success" type="submit"
                >Submit</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 d-md-block d-none">
        <div class="">
          <img
            src="../assets/imgs/businesscarimg.jpg"
            alt="..."
            class="car-img"
          />
          <img
            src="../assets/imgs/businessseat.jpg"
            alt="..."
            class="car-img"
          />
        </div>
      </div>
    </div>

    <div class="width-auto pad-set">
      <div class="py-2">
        <h1 class="text-center py-4">Frequently Ask Questions</h1>
      </div>
      <div class="row pb-5">
        <div class="col-md-6 col-sm-12">
          <div class="py-3 pl-4 bg-blue border-rad border-radb w-400">
            What is Vite App?
          </div>
          <div class="py-2 px-2 border-radb">
            Vite is an innovative software solution that simplifies how
            individuals and businesses find and access taxis, vans and delivery
            services. Our goal is to move Mauritius everyday and that includes
            your employees.
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="py-3 pl-4 bg-blue border-rad border-radb w-400">
            How Can Vite help my business?
          </div>
          <div class="py-2 px-2 border-radb">
            <ul>
              <li>
                Vite will ensure your employees will get to work on time, no
                more flat tires or commute issues.
              </li>
              <li>
                Vite is the most affordable taxi service in Mauritius with the
                assurance of confirming pricing directly through our app or
                online calculator. We offer businesses the flexibility of paying
                for trips daily, weekly or monthly. Whatever suits you!
              </li>
              <li>
                Vite is always available when you need it. We ensure you have
                drivers to call on when you need them.
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-2">
          <div>
            <div class="py-3 pl-4 bg-blue border-rad border-radb w-400">
              What is the cost of using Vite in Mauritius?
            </div>

            <div class="py-2 px-2 border-radb">
              Vite is 25% cheaper than traditional taxi and van services. Some
              examples of popular route fares:
              <ul>
                <li>Ebene Junction -> Grand Baie biz park = Rs 700</li>
                <li>Mauritius Airport -> Moka primary School = Rs 900</li>
                <li>Port Louis Post Office-> Flic en flac spar =Rs 575</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-2">
          <div>
            <div class="py-3 pl-4 bg-blue border-rad border-radb w-400">
              How do we track the number of trips?
            </div>

            <div class="py-2 px-2 border-radb">
              Vite offers you the following information about your company
              usage:
              <ul>
                <li>Total trips completed</li>
                <li>Total cost per week or month</li>
                <li>Breakdown of all trips</li>
                <ol style="list-style-type: lower-alpha">
                  <li>Cost of trips</li>
                  <li>Time and date of trip</li>
                  <li>Pick up and drop off locations</li>
                  <li>Employee who took the trip</li>
                </ol>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 mt-2">
          <div>
            <div class="py-3 pl-2 bg-blue border-rad border-radb w-400">
              Contact Vite
            </div>

            <div class="py-2 px-4 border-radb">
              <p>
                Please email Vite:
                <a href="mailto:Vitepartner@viteapp.co"></a>
                Vitepartner@viteapp.co
              </p>
              <p>
                Call or whatsapp:
                <a
                  href="https://api.whatsapp.com/send/?phone=23059150708&text&app_absent=0"
                  >(+230) 5915 0708</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      companyName: "",
      companySize: null,
      companyPhone: "",
      companyMail: "",
      size: [
        { value: null, text: "Company size", disabled: true },
        { value: 10, text: "1-10" },
        { value: 20, text: "10-20" },
        { value: 30, text: "20-30" },
      ],
    };
  },
};
</script>
<style scoped>
.w-100 {
  width: 100% !important;
  margin: 0;
  padding: 0;
}
.border-set {
  border-radius: 5px;
}
.position-form {
  position: absolute;
  top: 170px;
  left: -60px;
  z-index: 1;
  background: #f0f3fa;
}
.pad-setting {
  padding: 40px 60px 120px;
}
.height-1 {
  height: 45vh;
}
.bg-clr {
  background-color: #212529;
}
.colr {
  color: #fafcfe;
}
.ask-btn {
  background: whitesmoke;
  border: none;
  color: black;
  outline: none;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.43);
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.43);
}

.down-icon {
  font-size: 30px;
  color: #5bbae4;
}
.mark-icon {
  border: 1px solid #5bbae4;
  color: #5bbae4;
  background-color: #a7def7;
  border-radius: 50%;
  padding: 10px 13px;
}
.bg-blue {
  background-color: #a7def7;
}
.position-icon {
  position: absolute;
  left: 83%;
  top: 8px;
}
.car-img {
  height: 50vh;
  width: 50%;
}
.overx {
  overflow-x: hidden;
}
.pad-set {
  padding: 10px 150px;
}
.border-rad {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.border-radb {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 767px) {
  .position-form {
    position: absolute !important;
    top: -100px !important;
    left: 11% !important;
  }
  .pad-set {
    margin-top: 450px !important;
  }
  .width-auto {
    width: 100% !important;
    
    padding: 5%;
  }
}
@media screen and (max-width: 768px) {
  .position-form {
    position: absolute;
    left: -35px;
    width: 80%;
    top: 100px;
  }
}

</style>